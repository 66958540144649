<template>
  <b-navbar class="adminNavBar">
    <b-navbar-nav>
      <b-nav-item>
        <b-button variant="light" :to="{name:'Dashboard'}">{{ $t('general.my-media') }}</b-button>
      </b-nav-item>
      <b-nav-item>
        <b-button variant="light" :to="{name:'Profile'}">{{ $t('general.my-settings') }}</b-button>
      </b-nav-item>
    </b-navbar-nav>
    <b-navbar-nav class="ml-auto">
      <b-nav-item>
        <b-dropdown variant="primary" no-caret right>
          <template #button-content>
            <BIconPlus /> {{ $t('general.add-new-media')}}
          </template>
         <b-dropdown-item :to="{name: 'AddVideo'}">{{ $t('videos.add-video') }}</b-dropdown-item>
         <b-dropdown-item :to="{name: 'AddAudio'}">{{ $t('audio.add-audio') }}</b-dropdown-item>
         <b-dropdown-item :to="{name: 'AddImage'}">{{ $t('images.add-image') }}</b-dropdown-item>
       </b-dropdown>
      </b-nav-item>
    </b-navbar-nav>
  </b-navbar>
</template>

<script>
import { BIconPlus } from 'bootstrap-vue'
export default {
  name: "AdministratorNav",
  components: { BIconPlus },
  data: function() {
    return {
      user: this.$root.user
    }
  }
}
</script>

<style></style>
